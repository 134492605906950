import { gettext } from "django-i18n";
import React from "react";
import waffle from "_common/waffle";

/**
 * Global site footer.
 */
const Footer = ({ releaseName }) => {
  const year = new Date().getFullYear();
  return (
    <footer className="sde-footer">
      <div className="sde-container">
        <section className="sde-footer-copyright">
          {waffle.flag_is_active("ENABLE_SD_BLUEPRINT")
            ? gettext("© %s All rights reserved.").format(year)
            : gettext(
                "SD Elements v%s © %s SD Elements Inc. All rights reserved."
              ).format(releaseName, year)}
        </section>
      </div>
    </footer>
  );
};

export default Footer;
