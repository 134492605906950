import React from "react";

import clsx from "clsx";

import MaterialTextField from "@deprecated/material-ui/TextField";
import { TextFieldProps as MaterialTextFieldProps } from "@deprecated/material-ui";

import Tooltipify from "_common/components/tooltipify";
import { getTextFieldStyleProps } from "./utils";

export interface TextFieldProps extends MaterialTextFieldProps {
  maxCharacterCount?: number;
  maxLength?: number;
  tooltip?: string;
}

const mergePropsWithDefaults = (props: TextFieldProps) => ({
  ...props,
  ...getTextFieldStyleProps(props),
});

/**
 * Input component that can mimic a text input or textarea element.
 */
const TextField = React.forwardRef(
  (
    { maxCharacterCount, onKeyDown, ...props }: TextFieldProps,
    ref: React.Ref<MaterialTextField>
  ) => {
    const textField = (
      <div data-cy="field-root">
        <Tooltipify showOnEllipsis message={props.tooltip}>
          <MaterialTextField
            onKeyDown={(e) => {
              if (onKeyDown) {
                return onKeyDown(e);
              }
              // We need to do this for IE since the value
              // is lost when pressing Escape
              if (e.which === 27) {
                e.preventDefault();
              }
            }}
            ref={ref}
            {...mergePropsWithDefaults(props)}
          />
        </Tooltipify>
      </div>
    );

    if (maxCharacterCount) {
      const { errorText, maxLength, value } = props;
      const currentLength = value ? value.toString().length : 0;
      const isOver = currentLength > maxCharacterCount;

      const classes = clsx({
        "sde-text-field-count": true,
        "sde-character-count-error": isOver,
      });

      return (
        <div data-cy="field-root">
          {React.cloneElement(textField, {
            // force the red underline if the text is over the max
            errorText: errorText || (isOver && " "),
          })}
          <div className={classes}>
            {currentLength} / {maxCharacterCount || maxLength}
          </div>
        </div>
      );
    }

    return textField;
  }
);

TextField.defaultProps = {
  fullWidth: true,
  autoComplete: "off",
};

export default TextField;
